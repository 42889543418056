<!-- 视频详情页 -->
<template>
  <div id="doctorDetail">
    <Header></Header>
    <div class="new-breadcrumb">
      <ScienceBreadcrumb :text="text"></ScienceBreadcrumb>
      <div class="share-content">
        <van-popover
          v-model="showPopover"
          placement="bottom-end"
          trigger="click"
          @close="handleShareClose"
        >
          <div class="share-pop" style="padding: 0.75rem">
            <div class="title" style="margin-bottom: 0.75rem">分享视频</div>
            <div
              class="code-box"
              style="width: 100%; height: 10.875rem; margin-bottom: 0.75rem"
            >
              <div
                class="qrcode"
                style="display: flex; justify-content: center"
                ref="qrCodeDiv"
              ></div>
            </div>
            <div
              class="links-box"
              style="padding: 0.5rem; border: 1px solid #eee"
            >
              <p style="line-height: 1.5">{{ links }}</p>
            </div>
            <div
              class="copy-box"
              style="
                display: flex;
                justify-content: center;
                padding-top: 0.75rem;
              "
            >
              <van-button size="small" @click="copeLinks" type="default"
                >复制链接</van-button
              >
            </div>
          </div>
          <template #reference>
            <van-button
              round
              plain
              size="small"
              color="#008574"
              @click="shareLinks"
            >
              <i class="iconfont icon-fenxiang"></i>
            </van-button>
          </template>
        </van-popover>
      </div>
    </div>
    <div class="science-box">
      <div class="video-box">
        <div id="img-content" class="rich_media_wrp">
          <h1 class="rich_media_title" id="activity-name">
            {{ videoInfo.title }}
          </h1>
          <div class="video-summary">
            {{ videoInfo.summary }}
          </div>
          <div class="video-content">
            <!-- <d-player ref="player" :options="options"></d-player> -->
            <!-- <video controls style="width:100%">
            <source :src="options.video.url" type="video/mp4" />
            <source src="myVideo.webm" type="video/webm" />
            <p>
              你的浏览器不支持 HTML5 视频。这里有一个<a
                :href="options.video.url"
                :download="options.video.url"
              >视频</a>链接。
            </p>
          </video> -->
            <video
              width="100%"
              style="object-fit: fill"
              v-if="videoInfo.titleImage"
              :src="videoInfo.content"
              :poster="videoInfo.titleImage"
              controls
            >
              <source
                codecs="theora, vorbis"
                media="video/ogg"
                :src="videoInfo.content"
              />
              <source media="video/x-ms-wmv" :src="videoInfo.content" />
            </video>

            <video
              width="100%"
              style="object-fit: fill"
              :src="videoInfo.content"
              v-else
              controls
            >
              <source
                codecs="theora, vorbis"
                media="video/ogg"
                :src="videoInfo.content"
              />
              <source media="video/x-ms-wmv" :src="videoInfo.content" />
            </video>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>
    
<script>
import api from "../../../api/api";
import Header from "../../components/Header.vue";
import Footer from "../../components/Footer.vue";
import ScienceBreadcrumb from "../../components/ScienceBreadcrumb.vue";
import QRCode from "qrcodejs2";
export default {
  name: "doctorDetail",
  props: {},
  components: {
    Header,
    Footer,
    ScienceBreadcrumb,
  },
  data() {
    return {
      text: "吉博士视频详情",
      videoInfo: {},
      options: {
        // sources: [
        //   {
        //     type: "video/mp4",
        //     src: "",
        //   },
        // ],
        // poster: "",
        video: {
          url: "",
          pic: "",
        },
      },
      showPopover: false,
      links: "",
    };
  },
  created() {
    let id = this.$route.params.id;
    this.getDetailsVideo(id);
  },
  mounted() {},
  watch: {},
  methods: {
    getDetailsVideo(id) {
      api
        .getVideoById(id)
        .then((res) => {
          console.log(res.data);
          this.videoInfo = res.data.models[0];

          // this.$nextTick(() => {
          // this.$refs.player.src = this.videoInfo.content;

          // this.options["sources"][0]["src"] = this.videoInfo.content;
          // this.options.poster = this.videoInfo.titleImage;

          this.options.video.url = this.videoInfo.content;
          this.options.video.pic = this.videoInfo.titleImage;

          // console.log(this.options);
          // });
        })
        .catch((error) => {});
    },
    shareLinks() {
      // this.showPopover = !this.showPopover;
      // this.links = `http://localhost:8082/#/doctorDetail/${this.id}`;
      this.links = window.location.href;
      this.bindQRCode();
    },
    // 生成二维码函数
    bindQRCode() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.qrCodeDiv.innerHTML = "";
          new QRCode(this.$refs.qrCodeDiv, {
            text: this.links, //url地址  文本等需要转换为二维码的内容
            width: 156,
            height: 156,
            colorDark: "#333333", //二维码颜色
            colorLight: "#ffffff", //二维码背景色
            correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
          });
        }, 200);
      });
    },
    handleShareClose() {
      //清除二维码
      this.$refs.qrCodeDiv.innerHTML = "";
    },
    // 复制链接
    copeLinks() {
      // navigator.clipboard.writeText(this.links).then(() => {
      //   this.$toast.success("复制成功");
      // });

      var input = document.createElement("input"); // 创建input对象
      input.value = this.links; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$toast.success("复制成功");
    },
  },
};
</script>
    
<style lang="scss">
#doctorDetail {
  .new-breadcrumb {
    position: relative;
    padding-top: 5rem;
    .share-content {
      position: absolute;
      right: 0.5rem;
      top: 4.2rem;
      z-index: 10;
      .van-popover__wrapper {
        width: 2rem;
        height: 2rem;
      }
      .van-button--round {
        border-radius: 50%;
      }
      .van-button--small {
        padding: 0 0.438rem;
      }
      .share-pop {
        width: 100%;
        .code-box {
          .qrcode {
          }
        }
        .links-box {
          padding: 0.5rem;
          border: 1px solid #eee;
          p {
            line-height: 1.5;
          }
        }
        .copy-box {
          padding-top: 12px;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
  .science-box {
    position: relative;
    padding: 3.5rem 0 2rem;
    box-sizing: border-box;
    background-image: url("../../../assets/image/science/video-bg.png");
    background-size: cover;
    background-position: center center;
    .video-box {
      width: 90%;
      padding: 1rem;
      margin: auto;
      background-color: #fff;
      border-radius: 0.5rem;
      box-shadow: 0px 5px 20px 0px rgba(183, 183, 195, 0.07);
    }
    .rich_media_title {
      font-family: "SourceHanSansSC-Medium";
      font-size: 1.2rem;
      // line-height: 1.4;
      margin-bottom: 0.75rem;
      // text-align: center;
    }
    .video-summary {
      margin-bottom: 1.5rem;
      font-family: "OPlusSans3-Regular";
    }
    .video-content {
      // white-space: pre-wrap;
      font-family: "Microsoft Yahei";
      font-size: 1.125rem;
      color: #333;
      p {
        line-height: 2;
      }

      video::-webkit-media-controls-download-button {
        display:none;
      }

      // video::-webkit-media-controls-enclosure {
      //   overflow: hidden;
      // }
      // video::-webkit-media-controls-panel {
      //   width: calc(100% + 30px);
      // }
    }
  }
  .footer {
    background: #f5f5f5;
  }
  .video-content {
    img {
      max-width: 100% !important;
      display: block !important;
      margin: 1.25rem auto !important;
    }
    // p {
    //   font-family: "OPlusSans3-Regular";
    // }
    .ql-align-left {
      text-align: left;
    }
    .ql-align-center {
      text-align: center;
    }
    .ql-align-right {
      text-align: right;
    }
  }
}
</style>